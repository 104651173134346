<template>
  <div class="d-flex flex-column align-self-center" style="width: 50%">
    <Chart
      v-if="games.length > 0"
      :labels="games"
      label="Game 1 trials"
      :data="gamesLabel"
      id="games"
    ></Chart>
    <p class="text-center" v-else>No data</p>
    <Chart
      v-if="logins.length > 0"
      :labels="logins"
      label="Oculus app logins"
      :data="loginLabel"
      id="logins"
    ></Chart>
  </div>
  <div style="width: 50%">
    <Chart
      v-if="average.length > 0"
      :labels="average"
      label="Game 1 average time (minutes)"
      :data="averageLabel"
      id="average"
    ></Chart>
  </div>
</template>

<script>
import Chart from "@/components/general/Chart.vue";
import {
  userOculusLogins,
  userGameStats,
  userTrialAverage,
} from "@/services/user/user.js";

export default {
  name: "chartUsers",
  components: {
    Chart,
  },
  data() {
    return {
      logins: [],
      loginLabel: [],
      games: [],
      gamesLabel: [],
      average: [],
      averageLabel: [],
    };
  },
  methods: {
    getLogins() {
      userOculusLogins(this.$route.params.id).then((response) => {
        response.data.filter((obj) => this.logins.push(obj.date));
        response.data.filter((obj) => this.loginLabel.push(obj.count));
      });
    },
    getGameStas() {
      userGameStats(this.$route.params.id).then((response) => {
        response.data.filter((obj) => this.games.push(obj.start_date__date));
        response.data.filter((obj) => this.gamesLabel.push(obj.count));
      });
    },
    getGameAverage() {
      userTrialAverage(this.$route.params.id).then((response) => {
        this.average = Object.keys(response.data);
        this.averageLabel = Object.values(response.data);
      });
    },
  },
  created() {
    this.getLogins();
    this.getGameStas();
    this.getGameAverage();
  },
};
</script>
